import React, { type ReactNode, memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { getDesignTokenBannerColor } from '../../utils/banner-color/index.tsx';

type Props = {
	shouldHaveIndependentScroll?: boolean;
	bannerColor?: string | undefined;
	header?: ReactNode;
	children: ReactNode;
};

export const FormBackground = memo<Props>(({ children, bannerColor, header }: Props) => (
	<Background bannerColor={getDesignTokenBannerColor(bannerColor)}>
		{header}
		<ContentWrapper>{children}</ContentWrapper>
	</Background>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Background = styled.div<{
	bannerColor?: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ bannerColor }) =>
		`linear-gradient(to bottom, ${
			bannerColor || token('color.background.neutral.hovered')
		} 30%, transparent 30%)`,
	backgroundAttachment: 'fixed',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	width: '560px',
	marginTop: token('space.300'),
	marginRight: 'auto',
	marginBottom: token('space.300'),
	marginLeft: 'auto',
});
