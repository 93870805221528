// eslint-disable-next-line camelcase
import type { JiraBusinessFormWithFieldDataByFormUuid_jiraFormForPublicSubmission_fields_field } from '../../../services/get-form-public/__generated_apollo__/JiraBusinessFormWithFieldDataByFormUuid.ts';
import type { FieldNode, FieldOptionType } from '../../types/form-builder.tsx';

export const getFieldOptions = (
	field?:
		| FieldNode['field']['field']
		// eslint-disable-next-line camelcase
		| JiraBusinessFormWithFieldDataByFormUuid_jiraFormForPublicSubmission_fields_field,
): FieldOptionType[] | undefined => {
	if (!field) {
		return undefined;
	}

	switch (field.__typename) {
		case 'JiraRadioSelectField':
		case 'JiraSingleSelectField':
		case 'JiraMultipleSelectField':
		case 'JiraCheckboxesField':
			return field.fieldOptions?.edges
				?.map<FieldOptionType | undefined>((option) => {
					if (option?.node && option.node.value != null) {
						return {
							label: option.node.value,
							value: option.node.optionId,
						};
					}

					return undefined;
				})
				.filter(Boolean);
		case 'JiraPriorityField':
			return field.priorities?.edges
				?.map<FieldOptionType | undefined>((option) =>
					option?.node && option.node.name !== null
						? {
								label: option.node.name,
								value: option.node.priorityId,
								icon: option.node.iconUrl,
							}
						: undefined,
				)
				.filter(Boolean);
		// TODO TNK-2683 Implement JiraCascadingSelectField case
		default:
			return undefined;
	}
};
