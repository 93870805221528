import { di } from 'react-magnetic-di';
import { createSelector } from 'react-sweet-state';

import type { ViewType } from '../../../../common/types/context';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data';
import type { ScreenTabsDataPublic } from '../../../../common/types/screen-tabs';
import { isScreenTabsSupportEnabled } from '../../../view-configuration';
import type { IssueAdjustmentsState } from '../../types';

import type { ReadyPublicDataForOnInit } from './types';

export const selectReadyPublicDataForOnInit = createSelector<
	IssueAdjustmentsState,
	{ viewType: ViewType },
	ReadyPublicDataForOnInit | null,
	ViewType,
	Readonly<FormDataPublic | null>,
	number,
	ScreenTabsDataPublic | null,
	boolean
>(
	(_, props) => props.viewType,
	(state) => state.formData,
	(state) => state.numberOfSupportedFields,
	(state) => state.screenTabs,
	(state) => state.experienceDataIsLoading,
	(viewType, formData, numberOfSupportedFields, screenTabsData, experienceDataIsLoading) => {
		di(isScreenTabsSupportEnabled);

		if (
			formData !== null &&
			!isScreenTabsSupportEnabled(viewType) &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return {
				formData,
				screenTabsData: [],
			};
		}

		if (
			formData !== null &&
			screenTabsData !== null &&
			isScreenTabsSupportEnabled(viewType) &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return {
				formData,
				screenTabsData,
			};
		}

		return null;
	},
);
