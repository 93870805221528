import React, { useMemo } from 'react';
import { AsyncCascadingSelectEdit } from '@atlassian/jira-issue-field-cascading-select/src/async.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '../../common/utils/select/index.tsx';
import { validateCascadingSelectField } from '../../common/utils/validators/index.tsx';
import type { Props, CascadingSelectFieldConfig, FieldValue } from './types.tsx';
import { transformToCascadingSelectOptions, transformDefaultValue } from './utils.tsx';

const CascadingSelectField = (props: Props) => {
	const {
		fieldProps: { value, onChange, ...fieldProps },
		allowedValues: cascadingSelectOptions,
		width,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isReadOnly = false,
		isEditable = true,
	} = props;

	const { allowedValues, allowedChildValues } = useMemo(
		() => transformToCascadingSelectOptions(cascadingSelectOptions),
		[cascadingSelectOptions],
	);

	return (
		<FieldContainer width={width}>
			<AsyncCascadingSelectEdit
				{...fieldProps}
				value={value}
				allowedValues={allowedValues}
				allowedChildValues={allowedChildValues}
				// @ts-expect-error - TS2322 - Type '(e: ChangeEvent<HTMLElement>) => boolean' is not assignable to type '(e: SyntheticEvent<HTMLElement, Event>) => boolean'.
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				// @ts-expect-error - TS2322 - Type '(value: SelectedOption) => void' is not assignable to type '(value: SelectedOption, meta: null, analyticsEvent: UIAnalyticsEventInterface) => Promise<void>'.
				onChange={onChange}
				isInvalid={false} // Cascading select field handles invalid differently. TODO we will have to update field behaviour to correctly handle isInvalid.
				autoFocus={autoFocus}
				areOptionsOnSameRow={false}
				isEditable={!isReadOnly && isEditable}
			/>
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateCascadingSelectField,
	transformDefaultValue,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<CascadingSelectFieldConfig, SelectedOption>, any>'.
})<CascadingSelectFieldConfig, FieldValue>(CascadingSelectField);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateCascadingSelectField } from './utils';
