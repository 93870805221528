import memoize from 'lodash/memoize';
import {
	GREY_META,
	OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT as OPTION_COLOR_THEMES_COLORFUL_LABELS,
} from '../color-picker/constants.tsx';
import { getHashValueForText } from './utils/get-hash-value/index.tsx';
import { getRandomIndex } from './utils/get-random-index/index.tsx';

export const getAutoGeneratedColorForLabels = (option: string | undefined | null) => {
	if (option) {
		const hashValue = getHashValueForText(option);

		const randomIndex = getRandomIndex(hashValue);

		return OPTION_COLOR_THEMES_COLORFUL_LABELS[randomIndex].name;
	}
	return GREY_META.name;
};

export const getAutoGeneratedColorForLabelsTag = (option: string) => {
	if (option) {
		const hashValue = getHashValueForText(option);

		const randomIndex = getRandomIndex(hashValue);

		return OPTION_COLOR_THEMES_COLORFUL_LABELS[randomIndex].tagColor;
	}
	return GREY_META.tagColor;
};

export const memoizeGetAutoGeneratedColorForLabels = memoize(getAutoGeneratedColorForLabels);

export const memoizeGetAutoGeneratedColorForLabelsTag = memoize(getAutoGeneratedColorForLabelsTag);
