import React, { useContext, useCallback } from 'react';
import { CustomThemeButton } from '@atlaskit/button';
import PremiumIcon from '@atlaskit/icon/core/migration/premium';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AIUpsellPremiumTrialEntryPointContext } from './ai-upsell-premium-trial-entry-context.tsx';
import { messages } from './messages.tsx';

type Props = {
	onCloseParent?: () => void;
};

export const StartPremiumTrialButton = ({ onCloseParent }: Props) => {
	const { formatMessage } = useIntl();

	const { entryPointActions } = useContext(AIUpsellPremiumTrialEntryPointContext);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickAnalytics = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			actionSubject: 'button',
			action: 'clicked',
		});

		fireUIAnalytics(analyticsEvent, 'openPremiumTrialModalFromIssueAiUpsell');
	}, [createAnalyticsEvent]);

	const onClick = () => {
		// Nb. premium trial modal open is handled by entry point via ref
		onCloseParent && onCloseParent();
		onClickAnalytics();
	};

	return (
		<CustomThemeButton
			onClick={onClick}
			ref={triggerRef}
			iconBefore={
				<PremiumIcon LEGACY_size="small" label="" color={token('color.text.inverse', colors.N0)} />
			}
			theme={(current, themeProps) => ({
				buttonStyles: {
					...current(themeProps).buttonStyles,
					display: 'flex',
					alignItems: 'center',
					background: `linear-gradient(135deg, ${token(
						'color.background.discovery.bold',
						'#6E5DC6',
					)}, ${token('color.background.brand.bold', '#0C66E4')})`,
					color: token('color.text.inverse', colors.N0),
					outline: 'none',
					boxSizing: 'border-box',
					padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
					borderRadius: token('space.050', '4px'),
					height: token('space.400', '32px'),
					border: 'none',
					font: token('font.body'),
					gap: token('space.025', '2px'),
				},
				spinnerStyles: current(themeProps).spinnerStyles,
			})}
		>
			{formatMessage(messages.tryItFreeButton)}
		</CustomThemeButton>
	);
};
