import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { REQUEST_END, REQUEST_START } from '@atlassian/jira-business-performance/src/constants.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	JiraBusinessFormWithFieldDataByFormUuid,
	JiraBusinessFormWithFieldDataByFormUuidVariables,
} from './__generated_apollo__/JiraBusinessFormWithFieldDataByFormUuid';
import { JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_UUID } from './gql.tsx';
import { transformPublicFormDataWithFieldData, isFormNotFoundError } from './utils.tsx';
import type { FormNotFoundError } from './types.tsx';

/**
 * Used by public form submission
 */
export const useGetFormWithFieldDataByFormUuid = (formUuid: string) => {
	const QUERY_NAME = 'JiraBusinessFormWithFieldDataByFormUuid';
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		markViewMetric('form-submit-public', REQUEST_START);
	}, []);

	const { data, loading, error } = useQuery<
		JiraBusinessFormWithFieldDataByFormUuid,
		JiraBusinessFormWithFieldDataByFormUuidVariables
	>(JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_UUID, {
		/**
		 * Forms currently has no requirement to utilize a cache, however if
		 * this policy is changed, be aware that you may need to make other changes
		 * to ensure that GQL unions work as expected and/or the cache doesn't
		 * collide with other features.
		 * See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/45683/overview?commentId=2977330
		 */
		fetchPolicy: 'no-cache',
		variables: {
			publicFormUuid: formUuid,
		},
		onError: (apolloError) => {
			const analyticsError = apolloError;
			const skipSentry = isFormNotFoundError(analyticsError);
			if (skipSentry) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(analyticsError as FormNotFoundError).skipSentry = true;
			}

			fireErrorAnalytics({
				meta: {
					id: QUERY_NAME,
					packageName: 'jiraBusinessPublicForm',
					teamName: 'jira-werewolves',
				},
				error: analyticsError,
				sendToPrivacyUnsafeSplunk: true,
			});
			markViewMetric('form-submit', REQUEST_END);
		},
		onCompleted: () => {
			fireOperationalAnalytics(createAnalyticsEvent({}), `${QUERY_NAME} succeeded`);
			markViewMetric('form-submit', REQUEST_END);
		},
	});

	return { data: transformPublicFormDataWithFieldData({ data }), loading, error };
};
