import type { ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { FieldWidth } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldContainer = styled.div<{
	children?: ReactNode;
	width?: FieldWidth;
	marginTop?: number;
	paddingBottom?: number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: ({ width = isMobileAndInMvpOrExistingUsersExperiment() ? '100%' : 350 }) => {
		return typeof width === 'number' ? `${width}px` : width;
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ marginTop = 0 }) => `${marginTop}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ paddingBottom = 0 }) => `${paddingBottom}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.p<{
	children?: ReactNode;
}>({
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectContainer = styled.div<{
	children?: ReactNode;
}>({
	width: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Divider = styled.div<{
	children?: ReactNode;
}>({
	height: '1px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border', colors.N40),
	marginTop: token('space.300'),
	marginRight: 0,
	marginBottom: token('space.300'),
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CloseButtonContainer = styled.div<{
	children?: ReactNode;
}>({
	marginLeft: 'auto',
	marginRight: token('space.100'),
});
