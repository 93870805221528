import type { ApolloError } from 'apollo-client';
import type { MediaContext } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import {
	type Action,
	createHook,
	createStore,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { JiraBusinessFormWithFieldData as JiraBusinessPublicFormWithFieldData } from '../../../services/get-form-public/types.tsx';
import type { JiraBusinessFormWithFieldData } from '../../../services/get-form/types.tsx';

export type StoreType = {
	data?: JiraBusinessFormWithFieldData | JiraBusinessPublicFormWithFieldData;
	loading: boolean;
	error?: ApolloError;
};

export type MediaPermissionsProps = StoreType & {
	mediaContext: MediaContext | null;
};

const actions = {
	updateStore:
		(newData: StoreType): Action<StoreType> =>
		({ setState }) => {
			setState(newData);
		},
};

type Actions = typeof actions;

const FormSubmissionStore = createStore<StoreType, Actions>({
	initialState: {
		data: undefined,
		loading: false,
		error: undefined,
	},
	actions,
	name: 'JWMFormSubmission',
});

export const useFormSubmissionStore = createHook(FormSubmissionStore);

/**
 * Instead of grabbing from createmeta/businessform, public forms grabs field
 * meta data from JiraBusinessFormWithFieldDataByFormUuid store
 */
export const getFieldMetaFromStore = createSelector(
	[(state: StoreType) => state?.data?.fields ?? undefined],
	(issues) => issues,
);

export const useFieldMetaFromStore = createStateHook(FormSubmissionStore, {
	selector: getFieldMetaFromStore,
});
