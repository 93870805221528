import type { ScreenTabsValidationError } from './types';
import { UiModificationsValidationError } from './ui-modifications-validation-error';

type ScreenTabsValidationErrorAttributes = { errors: ScreenTabsValidationError[] };

export class UiModificationsScreenTabsValidationError extends UiModificationsValidationError {
	constructor(message: string, attributes: ScreenTabsValidationErrorAttributes, cause?: Error) {
		super(message, attributes, 'screenTabs', cause);
	}
}
