/*
 * This is used to calculate the hash value of the provided text,
 * allowing us to generate random hash values for the options in the Labels field.
 * As a result, we enhance the randomization of color assignments to Labels.
 */
export const HASH_RANDOMISER = 256;

/*
 * Where large numbers are involved,
 * (10^9 + 7) is often used as the modulus because it's a large prime number that fits well within standard integer data types,
 * allowing for efficient calculations while still providing a good distribution of remainders (hash values in our case),
 * preventing potential overflow issues when dealing with very large results.
 */
export const MOD_VALUE = 1000000007;
