import React, { useState, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const Loader = () => (
	<LoaderWrapper>
		<Spinner
			testId="forge-ui-renderers.common.ui.error-panel.forge-app-loading-spinner"
			size="large"
		/>
	</LoaderWrapper>
);

type ErrorPanelProps = {
	error: {
		message: string;
		errorDetails?: string;
		errorMessage?: string;
	};
	onRetry?: () => unknown;
};

export const ErrorPanel = ({ error, onRetry }: ErrorPanelProps) => {
	const { message, errorMessage, errorDetails } = error;
	const [isLoading, setIsLoading] = useState(false);
	const intl = useIntl();

	const onClickStopPropagation = useCallback((e: MouseEvent) => e.stopPropagation(), []);

	const reinitializeApp = useCallback(
		async (e: MouseEvent) => {
			setIsLoading(true);
			try {
				e.stopPropagation();
				if (onRetry) {
					await onRetry();
				}
			} catch {
				// catch
			} finally {
				setIsLoading(false);
			}
		},
		[onRetry],
	);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<ContentWrapper onClick={onClickStopPropagation}>
			<SectionMessage
				appearance="error"
				title={message}
				actions={
					onRetry
						? [
								<SectionMessageAction onClick={reinitializeApp} key="refresh">
									{intl.formatMessage(messages.refreshApp)}
								</SectionMessageAction>,
							]
						: []
				}
			>
				{errorMessage && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>}
				{errorDetails && <ErrorDetailsWrapper>{errorDetails}</ErrorDetailsWrapper>}
			</SectionMessage>
		</ContentWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	width: '100%',
	// The Editor sets styles for ul elements. Add a reset here.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	ul: {
		paddingLeft: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoaderWrapper = styled.div({
	textAlign: 'center',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
	width: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.p({ whiteSpace: 'pre-wrap' });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorDetailsWrapper = styled.pre({ whiteSpace: 'pre-wrap' });
