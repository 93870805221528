import isEqual from 'lodash/isEqual';

import type { ViewType } from '../../../../common/types/context';
import type { FieldId, FieldType } from '../../../../common/types/field';
import type { FieldMapInternalMetadata } from '../../../../common/types/field-internal-metadata';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data';
import { mapFieldValueFromInternalToPublic } from '../map-field-value-from-internal-to-public';

export const calculateNextFormValue = ({
	internalFormMetadata,
	formData,
	fieldId,
	fieldValue,
	fieldType,
	viewType,
}: {
	fieldId: FieldId;
	fieldValue: unknown;
	fieldType: FieldType;
	internalFormMetadata: FieldMapInternalMetadata;
	formData: FormDataPublic | null;
	viewType: ViewType;
}) => {
	if (formData === null) {
		return null;
	}

	let newPublicValue;

	try {
		newPublicValue = mapFieldValueFromInternalToPublic({
			fieldMetadata: internalFormMetadata[fieldId],
			internalFieldValue: fieldValue,
			fieldType,
			viewType,
		});
	} catch (e) {
		return null;
	}

	const currentPublicValue = formData[fieldId]?.value;
	if (isEqual(newPublicValue, currentPublicValue)) {
		return null;
	}

	return {
		...formData,
		[fieldId]: {
			...formData[fieldId],
			value: newPublicValue,
		},
	};
};
