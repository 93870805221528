import { nestedExpand as nestedExpandFactory, nestedExpandWithNonBodiedMacrosStage0 as nestedExpandWithNonBodiedMacrosStage0Factory } from '../../next-schema/generated/nodeTypes';

/**
 * @name nestedExpand_content
 * @minItems 1
 * @allowUnsupportedBlock true
 */

/**
 * @name nestedExpand_node
 */

/**
 * @name nestedExpand_with_no_marks_node
 */

function getExpandAttrs(domNode) {
  const dom = domNode;
  return {
    title: dom.getAttribute('data-title'),
    __expanded: true
  };
}
const nestedExpandFactoryOptions = {
  parseDOM: [{
    context: 'nestedExpand//',
    tag: '[data-node-type="nestedExpand"]',
    getAttrs: getExpandAttrs
  }, {
    tag: '[data-node-type="nestedExpand"] button',
    ignore: true
  }, {
    tag: '[data-node-type="expand"] button',
    ignore: true
  }, {
    tag: 'div[data-node-type="nestedExpand"]',
    getAttrs: getExpandAttrs
  }],
  toDOM(node) {
    const attrs = {
      'data-node-type': 'nestedExpand',
      'data-title': node.attrs.title,
      'data-expanded': node.attrs.__expanded
    };
    return ['div', attrs, 0];
  }
};

/**
 * @name nestedExpand
 * @description an expand that can be nested (eg. inside table, layout).
 */
export const nestedExpand = nestedExpandFactory(nestedExpandFactoryOptions);

/**
 * @name nestedExpandWithNonBodiedMacrosStage0
 * @description an expand that can be nested (eg. inside table, layout).
 */
export const nestedExpandWithNonBodiedMacrosStage0 = nestedExpandWithNonBodiedMacrosStage0Factory(nestedExpandFactoryOptions);