import isEmpty from 'lodash/isEmpty';
import type { IntlShape } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { IEnvironment } from 'relay-runtime';

import type { SupportedFieldProperty } from '../../../../../common/types';
import type { TenantContext, ViewType } from '../../../../../common/types/context';
import type { ExecutionContext } from '../../../../../common/types/execution-context';
import type { FieldId, FieldType } from '../../../../../common/types/field';
import type { FieldChangesPublic } from '../../../../../common/types/fields/field-changes';
import type { LookupData } from '../../../../../common/types/lookup';
import { FieldValueLookupError } from '../../../../../common/utils/errors/field-value-lookup-error';
import { getSupportedFieldConfiguration } from '../../../../view-configuration';
import { FIELD_VALIDATION_ERROR } from '../../../utils/errors/constants';
import { mapFieldPropertyToApiMethod } from '../../../utils/map-field-property-to-api-method';

import { VALID_RESULT } from './constants';
import type { FieldChangesTransformationResult, FieldPropertyTransformationResult } from './types';
import { filterInvalidResults, mapValidResultsToFieldInternalShape } from './utils';

type TransformFieldChangesToInternalShapePayload = {
	fieldType: FieldType;
	fieldId: FieldId;
	fieldFromIframe: FieldChangesPublic;
	lookupData: LookupData;
	viewType: ViewType;
	tenantContext: TenantContext;
	executionContext: ExecutionContext;
	environment: IEnvironment;
	intl: IntlShape;
};

export const transformFieldChangesToInternalShape = async ({
	fieldType,
	fieldId,
	fieldFromIframe,
	lookupData,
	viewType,
	tenantContext,
	executionContext,
	environment,
	intl,
}: TransformFieldChangesToInternalShapePayload): Promise<FieldChangesTransformationResult> => {
	di(getSupportedFieldConfiguration);
	const fieldConfiguration = getSupportedFieldConfiguration(viewType, fieldType);
	const fieldTransformers = fieldConfiguration?.publicToInternalTransformers;
	const fieldValidators = fieldConfiguration?.publicShapeValidators;

	if (!fieldFromIframe || !fieldTransformers || !fieldValidators) {
		return {
			...VALID_RESULT,
			data: {},
		};
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const validatorsArray = Object.keys(fieldValidators) as SupportedFieldProperty[];
	const lookupSource = fieldConfiguration?.lookupSource;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore - lookupData is not types on CI somehow
	const lookupValues = lookupSource ? lookupData[lookupSource] : undefined;

	const fieldTransformationResults: FieldPropertyTransformationResult[] = await Promise.all(
		validatorsArray.map(async (propertyName) => {
			if (!Object.prototype.hasOwnProperty.call(fieldFromIframe, propertyName)) {
				return VALID_RESULT;
			}
			const propertyValue = fieldFromIframe[propertyName];
			const transformer = fieldTransformers[propertyName];
			const fieldContext = { fieldId };

			// We need to catch lookup values discrepancies and react accordingly
			// to how we react to validation errors - throw an error
			// if the set value wasn't found in the array of lookupValues
			try {
				const data = transformer
					? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						await transformer(propertyValue, lookupValues as any, {
							tenantContext,
							executionContext,
							environment,
							fieldContext,
							intl,
						})
					: propertyValue;
				return { isValid: true, data: { [propertyName]: data } };
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				// FieldValueLookupError will be thrown from the transformer
				// when the lookup value wasn't found
				if (err instanceof FieldValueLookupError) {
					// For single value fields its straightforward to just pass
					// the faulty propertyValue into error message,
					// but for multi value fields we should find out the faulty values from the array
					// thats why we should propagate the faulty values as a comma separated string
					// by using the error's message
					const faultyValue = err.message || propertyValue;

					return {
						isValid: false,
						error: {
							fieldId,
							fieldType,
							type: FIELD_VALIDATION_ERROR,
							method: mapFieldPropertyToApiMethod(propertyName),
							message: `Invalid IDs provided: ${faultyValue}`,
						},
					};
				}
				throw err;
			}
		}),
	);

	const invalidTransformationResults = filterInvalidResults(fieldTransformationResults);

	if (!isEmpty(invalidTransformationResults)) {
		return {
			isValid: false,
			errors: invalidTransformationResults.map((result) => result.error),
		};
	}

	return mapValidResultsToFieldInternalShape(fieldTransformationResults);
};
