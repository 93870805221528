import isEmpty from 'lodash/isEmpty';
import type { IntlShape } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { Action } from 'react-sweet-state';
import type { IEnvironment } from 'relay-runtime';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalEvent } from '@atlassian/ui-modifications-analytics';

import {
	LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY,
	LOOKUP_SOURCE_USERS_ASYNC_DATA,
} from '../../../../common/constants';
import type { TenantContext } from '../../../../common/types/context';
import type { ExecutionContext } from '../../../../common/types/execution-context';
import type {
	AppliedFieldsChanges,
	Field,
	FieldId,
	FieldMapFromIframe,
} from '../../../../common/types/field';
import type { FieldChangesMapPublic } from '../../../../common/types/fields/field-changes';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data';
import type { LookupData } from '../../../../common/types/lookup';
import type { AppId, ChangeId, IssueAdjustmentsState, StoreContainerProps } from '../../types';
import type { FieldValidationError } from '../../utils/errors/types';
import { UiModificationsFieldsValidationError } from '../../utils/errors/ui-modifications-field-validation-error';
import { fieldWasRegistered } from '../../utils/field-was-registered';
import { mapFieldPropertyNamesToInternal as mapFieldPropsFromPublicToInternalShape } from '../../utils/map-field-props-from-public-to-internal-shape';
import { prepareErrorsArray } from '../../utils/prepare-errors-array';
import { transformFieldToIframe } from '../../utils/supported-field-properties';
import { addAppsErrors } from '../app-errors';
import { collectFieldsAppliedChangesHistory } from '../applied-changes-history/collect-history';

import { transformFieldChangesToInternalShape } from './transform-field-changes-to-internal-shape';

type UpdateFieldsPayload = {
	changeId: ChangeId;
	fieldsChanges: FieldMapFromIframe;
	lookupDataFromApi: Exclude<LookupData, typeof LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY>;
	tenantContext: TenantContext;
	executionContext: ExecutionContext;
	environment: IEnvironment;
	appId: AppId;
	intl: IntlShape;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

/**
 * Updates fields with the provided changes.
 */
export const updateFields =
	({
		changeId,
		fieldsChanges,
		lookupDataFromApi,
		tenantContext,
		executionContext,
		environment,
		appId,
		intl,
		createAnalyticsEvent,
	}: UpdateFieldsPayload): Action<IssueAdjustmentsState, StoreContainerProps, Promise<void>> =>
	async ({ setState, getState, dispatch }, { viewType }) => {
		di(collectFieldsAppliedChangesHistory);

		const appliedChanges = getState().appliedChanges ?? {};
		const formData = getState().formData ?? {};
		const { internalFormMetadata, registeredFields } = getState();

		const { changesToApply, incomingFormData, errorsByField, newAppliedChangesPublic } =
			await Object.keys(fieldsChanges).reduce(
				async (
					acc: Promise<{
						changesToApply: AppliedFieldsChanges;
						newAppliedChangesPublic: FieldChangesMapPublic;
						incomingFormData: FormDataPublic;
						errorsByField: { [key: string]: FieldValidationError[] };
					}>,
					fieldId: FieldId,
				) => {
					// Do not update fields that are not inside initialized data.
					// Validates if the field that comes from Iframe is supported
					// - because we add to formData only the supported ones.
					const fieldData: Field = formData[fieldId];
					const internalMetadata = internalFormMetadata[fieldId];
					const fieldIsRegistered = fieldWasRegistered(registeredFields, changeId, fieldId, appId);
					// Only registered field Ids can be updated.
					if (!fieldIsRegistered || !fieldData) {
						const { fieldType } = fieldData ?? {};
						dispatch(
							addAppsErrors({
								errors: {
									[appId]: [
										{
											fieldType,
											fieldId,
											type: 'APPLY_CHANGES_FOR_UNREGISTERED_FIELD',
											message: 'Application tried to apply changes to unregistered field',
										},
									],
								},
							}),
						);

						fireOperationalEvent(createAnalyticsEvent, {
							action: 'unregisteredField',
							actionSubject: 'jiraUiModifications.applyChanges',
							attributes: {
								message: 'Attempt to apply changes to a field that was not registered',
								fieldId,
								viewType,
							},
						});

						return acc;
					}

					const lookupData: LookupData = {
						[LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY]: internalMetadata.allowedValues,
						[LOOKUP_SOURCE_USERS_ASYNC_DATA]: lookupDataFromApi[LOOKUP_SOURCE_USERS_ASYNC_DATA],
					};

					// It updates properties in each field to avoid removing unchanged props for
					// selected field by another app or another onChange update.
					// Only supported properties are allowed.
					const supportedFieldChanges = await transformFieldChangesToInternalShape({
						fieldType: fieldData.fieldType,
						fieldId,
						fieldFromIframe: fieldsChanges?.[fieldId],
						lookupData,
						viewType,
						tenantContext,
						executionContext,
						environment,
						intl,
					});

					if (supportedFieldChanges.isValid === false) {
						(await acc).errorsByField[fieldId] = supportedFieldChanges.errors;
						return acc;
					}

					// It converts changes to 'public' format to update the formData
					const publicShapeChanges = transformFieldToIframe(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
						lookupData,
						fieldsChanges?.[fieldId],
						internalMetadata,
					);

					const transformedFieldChanges = mapFieldPropsFromPublicToInternalShape(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
					);

					const combinedChangesToApply = {
						...appliedChanges[fieldId],
						...transformedFieldChanges,
					};

					if (!isEmpty(combinedChangesToApply)) {
						(await acc).newAppliedChangesPublic[fieldId] = publicShapeChanges;
						(await acc).changesToApply[fieldId] = combinedChangesToApply;
						(await acc).incomingFormData[fieldId] = {
							...formData[fieldId],
							...publicShapeChanges,
						};
					}

					return acc;
				},
				Promise.resolve({
					changesToApply: { ...appliedChanges },
					incomingFormData: { ...formData },
					newAppliedChangesPublic: {},
					errorsByField: {},
				}),
			);

		if (!isEmpty(errorsByField)) {
			throw new UiModificationsFieldsValidationError('Transformation failed', {
				errors: prepareErrorsArray(errorsByField),
			});
		}

		setState({
			appliedChanges: changesToApply,
			formData: incomingFormData,
		});

		dispatch(
			collectFieldsAppliedChangesHistory({
				appId,
				changeId,
				fieldsChanges: newAppliedChangesPublic,
			}),
		);
	};
