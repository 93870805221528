import React, { useState, useMemo, useCallback, useRef, type FocusEvent, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';
import type { StylesConfig } from '@atlaskit/select';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { SessionId } from '@atlassian/jira-issue-analytics/src/common/types.tsx';
import { FailedFetchOptionWrapper } from '@atlassian/jira-issue-field-select-base/src/ui/failed-fetch-options/index.tsx';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-field-select-base/src/ui/filter-options-by-label-and-filter-values/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import { getAutoGeneratedColorForLabels } from '@atlassian/jira-option-color-picker/src/option-color-generator/index.tsx';
import { OptionTag } from '@atlassian/jira-option-color-picker/src/option-tag/index.tsx';
import { getColorMetadata } from '@atlassian/jira-option-color-picker/src/color-picker/utils/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { mapLabelsResponseToLabelOptions } from '../../common/utils.tsx';
import { useLabelsSuggestions } from '../../services/suggestions/index.tsx';
import CreatableSelectWithAnalytics from './creatable-select-with-analytics/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const FETCH_DEBOUNCE = 300;

const labelsValueStyles: StylesConfig<Option, true> = {
	multiValue: (base, { data }) => {
		// @ts-expect-error - ts2345 - Argument of type 'ReactNode' is not assignable to parameter of type 'string | null | undefined'.
		const { backgroundColor } = getColorMetadata(getAutoGeneratedColorForLabels(data.label));
		return {
			...base,
			border: `1px solid ${backgroundColor}`,
			'& > div > div > div': {
				paddingLeft: 0,
				paddingRight: 0,
			},
		};
	},
	multiValueLabel: (base) => ({
		...base,
		'& > div > div': {
			borderColor: 'transparent',
		},
	}),
};

const selectStyle = {
	...defaultSelectStyles,
	...labelsValueStyles,
};

export const LabelsEdit = (props: Props) => {
	const {
		autoCompleteUrl,
		autoFocus,
		onChange,
		onFocus,
		onBlur,
		spacing,
		value,
		options,
		cachedOptions = [],
		formatCreateLabel,
		noOptionsMessage,
		loadingMessage,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		getSuggestions,
		fetchSuggestionsOnMount = true,
		fieldId = LABELS_TYPE,
		isInvalid = false,
		isDisabled = false,
		ariaLabel = '',
		inputId,
		isRequired,
		openMenuOnFocus,
	} = props;

	const [suggestions, setSuggestions] = useState<Option[]>();
	const [defaultSuggestions, setDefaultSuggestions] = useState<Option[]>();
	const [hasLastFetchFailed, setHasLastFetchFailed] = useState<boolean>(false);
	const lastQuery = useRef<string>('');
	const sessionId = useRef<SessionId>(uuid());

	const { formatMessage } = useIntl();
	const [{ isLoadingSuggestions }, { getLabelsSuggestions, setIsLoadingSuggestions }] =
		useLabelsSuggestions({ fieldId, getSuggestions });

	const getFailedFetchMessage = useCallback(
		() => (
			<FailedFetchOptionWrapper>{formatMessage(messages.failedFetch)}</FailedFetchOptionWrapper>
		),
		[formatMessage],
	);

	const fetchSuggestions = useCallback(
		async (query = ''): Promise<void> => {
			try {
				const response: LabelsSuggestionList = await getLabelsSuggestions(
					autoCompleteUrl,
					query,
					sessionId.current,
				);

				setIsLoadingSuggestions(false);

				if (lastQuery.current === query) {
					const results = mapLabelsResponseToLabelOptions(response);

					setSuggestions(results);

					if (query === '') {
						setDefaultSuggestions(results);
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setIsLoadingSuggestions(false);
				setHasLastFetchFailed(true);
				setSuggestions([
					{
						label: getFailedFetchMessage(),
						value: '',
						isDisabled: true,
					},
				]);
			}
		},
		[
			autoCompleteUrl,
			getFailedFetchMessage,
			getLabelsSuggestions,
			lastQuery,
			sessionId,
			setHasLastFetchFailed,
			setIsLoadingSuggestions,
		],
	);

	if (fg('_jira-concurrent-state-update-hasnt-mounted-yet')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (fetchSuggestionsOnMount) {
				if (!options && !defaultSuggestions) {
					setIsLoadingSuggestions(true);
					fetchSuggestions();
				} else if (options && !defaultSuggestions) {
					setDefaultSuggestions(options);
					setSuggestions(options);
				} else {
					lastQuery.current = '';
					setIsLoadingSuggestions(true);
					fetchSuggestions();
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useMemo(() => {
			if (fetchSuggestionsOnMount) {
				if (!options && !defaultSuggestions) {
					setIsLoadingSuggestions(true);
					fetchSuggestions();
				} else if (options && !defaultSuggestions) {
					setDefaultSuggestions(options);
					setSuggestions(options);
				} else {
					lastQuery.current = '';
					setIsLoadingSuggestions(true);
					fetchSuggestions();
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
	}

	const groupedSuggestions = useMemo(
		() => [
			{
				label: formatMessage(messages.recent),
				options: cachedOptions,
			},
			{
				label: formatMessage(messages.all),
				options: suggestions || [],
			},
		],
		[cachedOptions, formatMessage, suggestions],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, FETCH_DEBOUNCE), []);

	const getCreateLabel = useCallback(
		(inputValue: string): string =>
			`${inputValue} (${
				formatCreateLabel !== undefined ? formatCreateLabel : formatMessage(messages.createNewItem)
			})`,
		[formatCreateLabel, formatMessage],
	);

	const getNoOptionsMessage = useCallback(
		(): string =>
			noOptionsMessage !== undefined ? noOptionsMessage : formatMessage(messages.empty),
		[formatMessage, noOptionsMessage],
	);
	const getLoadingMessage = useCallback(
		(): string => (loadingMessage !== undefined ? loadingMessage : formatMessage(messages.loading)),
		[formatMessage, loadingMessage],
	);

	const filterOption = useCallback(
		(option: Option, query: string): boolean => {
			if (hasLastFetchFailed) {
				// If the fetch has failed, we will show a disabled option to indicate the error to users
				return true;
			}
			return filterOptionByLabelAndFilterValues<Option>(option, query || '');
		},
		[hasLastFetchFailed],
	);

	const onQueryChange = useCallback(
		(query: string): void => {
			debouncedFetchSuggestions.cancel();
			setHasLastFetchFailed(false);
			if (query) {
				lastQuery.current = query;
				setIsLoadingSuggestions(true);
				debouncedFetchSuggestions(query);
			} else {
				lastQuery.current = '';
				setIsLoadingSuggestions(false);
				setSuggestions(defaultSuggestions);
			}
		},
		[debouncedFetchSuggestions, defaultSuggestions, setIsLoadingSuggestions, setSuggestions],
	);

	const onSelectFocus = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			if (!suggestions) {
				setIsLoadingSuggestions(true);
				fetchSuggestions('');
			}
			onFocus && onFocus(e);
		},
		[suggestions, onFocus, setIsLoadingSuggestions, fetchSuggestions],
	);

	const onSelectBlur = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			onBlur && onBlur(e);
		},
		[onBlur],
	);

	const menuPosition = isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined;

	const isLoading = isLoadingSuggestions;

	return (
		<CreatableSelectWithAnalytics
			isMulti
			autoFocus={autoFocus}
			fieldId={fieldId}
			options={groupedSuggestions}
			value={value}
			isDisabled={isDisabled}
			onBlur={onSelectBlur}
			menuPosition={menuPosition}
			styles={
				expVal('adding-color-to-labels-field-in-jira-experiment', 'isColoredLabelsEnabled', false)
					? selectStyle
					: defaultSelectStyles
			}
			onFocus={onSelectFocus}
			noOptionsMessage={getNoOptionsMessage}
			closeMenuOnScroll={onCloseMenuOnScroll}
			loadingMessage={getLoadingMessage}
			placeholder={formatMessage(messages.placeholder)}
			onInputChange={onQueryChange}
			onChange={onChange}
			spacing={spacing}
			isLoading={isLoading}
			filterOption={filterOption}
			formatCreateLabel={getCreateLabel}
			{...(expVal(
				'adding-color-to-labels-field-in-jira-experiment',
				'isColoredLabelsEnabled',
				false,
			)
				? { formatOptionLabel: (option: Option) => SelectableOptions(option) }
				: {})}
			validationState={isInvalid === true ? 'error' : null}
			sessionId={sessionId.current}
			aria-label={ariaLabel}
			inputId={inputId}
			isRequired={isRequired}
			openMenuOnFocus={openMenuOnFocus}
		/>
	);
};

export const SelectableOptions = (option: Option) => {
	// @ts-expect-error - ts2322 - Type 'ReactNode' is not assignable to type 'string'.
	return <OptionTag name={option.label} color={getAutoGeneratedColorForLabels(option.label)} />;
};

export default LabelsEdit;
