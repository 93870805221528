import type { ApolloError } from 'apollo-client';
import { fg } from '@atlassian/jira-feature-gating';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import type { FormField } from '../create-form/types.tsx';
import type { JiraBusinessFormById } from './__generated_apollo__/JiraBusinessFormById';
import type { JiraBusinessFormWithFieldDataByFormId } from './__generated_apollo__/JiraBusinessFormWithFieldDataByFormId';
import { FORM_NOT_FOUND_ERROR, IGNORE_FIELD_IDS } from './constants.tsx';
import type {
	JiraBusinessForm,
	JiraBusinessFormWithFieldData,
	JiraBusinessFormField,
	JiraBusinessFormWithFieldDataField,
} from './types.tsx';

export const formFieldFilter = (field: JiraBusinessFormField) =>
	// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"reporter" | "issuetype"'.
	IGNORE_FIELD_IDS.includes(field.fieldId) === false;

export const fieldDataFieldFilter = (field: JiraBusinessFormWithFieldDataField) =>
	// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"reporter" | "issuetype"'.
	IGNORE_FIELD_IDS.includes(field.fieldId) === false;

export const transformJiraBusinessForm = ({
	data,
}: {
	data?: JiraBusinessFormById | null;
}): JiraBusinessForm | undefined => {
	if (!data || !data.jiraBusinessForm) {
		return undefined;
	}

	const { jiraBusinessForm } = data;

	// the mapping from GraphQL type to FE type
	// mainly affects the `fields` only
	// this is done to remove the `undefined` from the fields array so it will be easier to consume in the UI layer
	const form: JiraBusinessForm = {
		id: jiraBusinessForm.id,
		...(fg('jira-forms-public-access') && {
			uuid: jiraBusinessForm.uuid,
		}),
		formId: jiraBusinessForm.formId,
		projectId: jiraBusinessForm.projectId,
		title: jiraBusinessForm.title,
		description: jiraBusinessForm.description ?? undefined,
		bannerColor: jiraBusinessForm.bannerColor ?? undefined,
		enabled: jiraBusinessForm.enabled,
		isSubmittableByAllLoggedInUsers: jiraBusinessForm.isSubmittableByAllLoggedInUsers,
		issueType: jiraBusinessForm.issueType
			? {
					id: jiraBusinessForm.issueType.issueTypeId ?? '',
					name: jiraBusinessForm.issueType.name ?? '',
					avatarId: jiraBusinessForm.issueType.avatarId ?? null,
					avatarUrl: jiraBusinessForm.issueType.avatar?.medium ?? null,
					isDefault: false,
					subtask: jiraBusinessForm.issueType.type === 'SUB_TASK',
					hierarchyLevel: jiraBusinessForm.issueType.hierarchy?.level ?? 0,
				}
			: null,
		fields:
			jiraBusinessForm.fields
				?.filter(Boolean)
				.map((f) => ({
					id: f.id,
					fieldId: f.fieldId,
					alias: f.alias ?? undefined,
					isRequiredByForm: !!f.isRequired,
					description: f.description ?? undefined,
					...(fg('jira_forms_conditional_field_logic_gate_') && {
						conditions: f.conditions?.concat(),
						parentCondition: f.parentCondition,
						field: f.field,
					}),
				}))
				.filter(formFieldFilter) ?? null,
		accessLevel: jiraBusinessForm.accessLevel
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(jiraBusinessForm.accessLevel as FormAccessLevelTypes)
			: FormAccessLevelTypes.LIMITED,
	};

	return form;
};

export const transformFormDataWithFieldData = ({
	data,
}: {
	data?: JiraBusinessFormWithFieldDataByFormId | null;
}): JiraBusinessFormWithFieldData | undefined => {
	if (!data || !data.jiraBusinessForm) {
		return undefined;
	}

	const { jiraBusinessForm } = data;

	return {
		formId: jiraBusinessForm.formId,
		projectId: jiraBusinessForm.projectId,
		issueType: jiraBusinessForm.issueType
			? {
					name: jiraBusinessForm.issueType.name || '',
					id: jiraBusinessForm.issueType.issueTypeId ?? '',
				}
			: null,
		title: jiraBusinessForm.title,
		description: jiraBusinessForm.description ?? undefined,
		bannerColor: jiraBusinessForm.bannerColor ?? undefined,
		enabled: jiraBusinessForm.enabled,
		fields:
			jiraBusinessForm.fields
				?.filter(Boolean)
				.filter((f) => 'fieldConfig' in f.field)
				.map((f) => {
					const fieldIsRequiredByIssueConfig =
						'fieldConfig' in f.field && Boolean(f.field.fieldConfig?.isRequired);
					const fieldIsRequiredByFormConfig = !!f.isRequired;

					return {
						fieldId: f.fieldId,
						alias: f.alias,
						name: f.field.name,
						isRequired: fieldIsRequiredByIssueConfig,
						isRequiredByForm: fieldIsRequiredByFormConfig,
						description: f.description ?? undefined,
						type: f.field.type,
						...(fg('jira_forms_conditional_field_logic_gate_') && {
							parentCondition: f.parentCondition,
						}),
						...(fg('forms_multiline_text_wiki_renderer') && {
							// @ts-expect-error - TS2339: Property 'renderer' does not exist on type 'JiraBusinessFormWithFieldDataByFormId_jiraBusinessForm_fields_field'.
							renderer: f.field.renderer ?? undefined,
						}),
					};
				})
				.filter(fieldDataFieldFilter) ?? [],
		accessLevel: jiraBusinessForm.accessLevel
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(jiraBusinessForm.accessLevel as FormAccessLevelTypes)
			: FormAccessLevelTypes.LIMITED,
	};
};

export const isFormNotFoundError = (error: ApolloError) =>
	error.graphQLErrors?.[0]?.extensions?.errorType === FORM_NOT_FOUND_ERROR;

export const formFieldByIdMatcher = (fieldId: string) => (field: JiraBusinessFormField) =>
	field.fieldId === fieldId;

export const availableFieldByIdMatcher = (fieldId: string) => (field: FormField) =>
	field.fieldId === fieldId;
