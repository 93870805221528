import gqlTagGira from 'graphql-tag';
import {
	FIELD_PARENT_CONDITION_FRAGMENT,
	FORM_BUILDER_CONFIGURATION_FRAGMENT,
} from '../constants.tsx';

/**
 * This is used for Form builder
 */
export const JIRA_BUSINESS_FORM_BY_ID = gqlTagGira`
  query JiraBusinessFormById($formId: ID!, $isConditionalFieldsEnabled: Boolean!, $isPublicFormsEnabled: Boolean!) {
    jiraBusinessForm(formId: $formId) {
      ...FormBuilderConfigurationFragment
    }
  }

  ${FORM_BUILDER_CONFIGURATION_FRAGMENT}
`;

/**
 * This is used for Form submission
 */
export const JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_ID = gqlTagGira`
  query JiraBusinessFormWithFieldDataByFormId(
		$formId: ID!,
		$isConditionalFieldsEnabled: Boolean!
	) {
    jiraBusinessForm(formId: $formId) {
      formId
      projectId
      issueType {
        name
        issueTypeId
      }
      title
      description
      bannerColor
      enabled
      accessLevel
      fields {
        fieldId
        alias
        description
        isRequired
				parentCondition @include(if: $isConditionalFieldsEnabled) {
					...FieldParentConditionFragment
				}
        field {
          name
          type
          ... on GenericIssueField {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraIssueFieldConfiguration {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraPriorityField {
            fieldConfig {
              isRequired
            }
          }
          ... on JiraRichTextField {
            renderer
          }
        }
      }
    }
  }

	${FIELD_PARENT_CONDITION_FRAGMENT}
`;
