import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const CheckboxWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `2px solid ${token('color.border', colors.N30)}`,
	alignItems: 'center',
});
