import type { FieldValidationError } from './types';
import { UiModificationsValidationError } from './ui-modifications-validation-error';

type FieldValidationErrorAttributes = { errors: FieldValidationError[] };

export class UiModificationsFieldsValidationError extends UiModificationsValidationError {
	constructor(message: string, attributes: FieldValidationErrorAttributes, cause?: Error) {
		super(message, attributes, 'fields', cause);
	}
}
