import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { LoadingButton } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import Form, { FormFooter } from '@atlaskit/form';
import { Box, Inline, xcss, Text, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { errorFlags, FlagRenderer, type FlagConfiguration } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	ContextualAnalyticsData,
	FireTrackAnalytics,
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FORM_VALIDATION_ERROR, PACKAGE_NAME } from '../../../common/constants.tsx';
import AsyncDescriptionReadView from '../../../common/ui/description-read/index.tsx';
import { SuccessView } from '../../../common/ui/empty-views/index.tsx';
import { TitleWrapperTruncated } from '../../../common/ui/form-header/styled.tsx';
import { getFieldTypeConfig } from '../../../common/utils/field-config/index.tsx';
import { useJWMFormsFeatures } from '../../../controllers/features-context/index.tsx';
import { useFormSubmission } from '../../../controllers/form-submission/index.tsx';
import { useMediaPermissions } from '../../../controllers/media-upload-permissions/index.tsx';
import { useCreateIssue } from '../../../services/create-issue/index.tsx';
import type { FormInputData } from '../../../services/create-issue/types.tsx';
import type { JiraBusinessFormWithFieldDataField } from '../../../services/get-form/types.tsx';
import { useUploadPermissions } from '../../../services/media-upload-permissions/index.tsx';
import { getFormAttachmentUploadUrl } from '../../../services/media-upload-permissions/utils.tsx';
import { FormAccessLevelTypes } from '../../../common/types.tsx';
import Condition from './condition/index.tsx';
import messages from './messages.tsx';
import ScreenreaderAnnouncer from './screenreader-announcer/index.tsx';
import { generateFieldAnalytics, useIntersectionObserver } from './utils.tsx';
import { PublicFormPrivacyPolicyFooter } from './public-form-privacy-policy-footer/index.tsx';

const submissionErrorFlag = (errorMessage: string): FlagConfiguration => {
	if (errorMessage === FORM_VALIDATION_ERROR.ATTACHMENT_ERROR) {
		return {
			type: 'error',
			title: messages.submissionErrorFlag,
			description: messages.submissionAttachmentErrorContent,
			testId: 'business-form.ui.form-submission.fill-in-view.form-attachment-error-flag',
			messageId: 'business-form.ui.form-submission.fill-in-view.error.form-attachment-error-flag',
			messageType: 'transactional',
		};
	}
	return {
		type: 'error',
		title: messages.submissionErrorFlag,
		description: errorMessage,
		testId: 'business-form.ui.form-submission.fill-in-view.error-flag',
		messageId: 'business-form.ui.form-submission.fill-in-view.error',
		messageType: 'transactional',
	};
};

const interactions = ['scroll', 'click', 'focus', 'keydown'];
const hasInitialInteractionCapture = true;

type FormFooterProps = {
	isPublicForm: boolean;
	createIssueLoading: boolean;
	uploading: boolean;
};

const FormFooterOld = (prop: FormFooterProps) => {
	const { createIssueLoading, uploading } = prop;
	const { formatMessage } = useIntl();

	return (
		<FormFooter>
			<LoadingButton
				testId="business-form.ui.form-submission.fill-in-view.loading-button"
				type="submit"
				appearance="primary"
				isLoading={createIssueLoading || uploading}
				interactionName="business-form-submission-submit"
			>
				{formatMessage(messages.submitButton)}
			</LoadingButton>
		</FormFooter>
	);
};

const FormFooterNew = (prop: FormFooterProps) => {
	const { isPublicForm, createIssueLoading, uploading } = prop;
	const { formatMessage } = useIntl();
	return (
		<Stack>
			{isPublicForm && <PublicFormPrivacyPolicyFooter />}
			<Box xcss={submitButtonStyle}>
				<Button
					testId="business-form.ui.form-submission.fill-in-view.loading-button"
					type="submit"
					appearance="primary"
					isLoading={createIssueLoading || uploading}
					interactionName="business-form-submission-submit"
				>
					{formatMessage(messages.submitButton)}
				</Button>
			</Box>
		</Stack>
	);
};

const Footer = componentWithFG('jira-forms-public-access', FormFooterNew, FormFooterOld);

export const FillInView = () => {
	const [uploading, setUploading] = useState<boolean>(false);
	const [shouldRenderExtraFields, setShouldRenderExtraFields] = useState<boolean>(false);
	const [isVisible, setIsVisible] = useState<boolean>(true);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data: formSubmissionData, formId } = useFormSubmission();
	const { data: projectData } = useProject_DEPRECATED_DO_NOT_USE();
	const { shouldHidePoweredBy } = useJWMFormsFeatures();
	const { formWithFieldData, fieldMetaData } = formSubmissionData ?? {};
	const { description, projectId, accessLevel, issueType } = formWithFieldData ?? {};
	// This is for description field
	const { uploadPermissions, fetchUploadPermissions, uploadPermissionStatus } =
		useUploadPermissions(true);
	const { mediaUploadPermissions, mediaUploadPermissionStatus, mediaContext } =
		useMediaPermissions();
	const isUploadPermissionNotReady =
		uploadPermissionStatus !== 'COMPLETED' || mediaUploadPermissionStatus !== 'COMPLETED';

	const parsedDescription = useMemo(() => {
		if (description === undefined || description === '') {
			return undefined;
		}
		try {
			return JSON.parse(description);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			log.safeErrorWithoutCustomerData(
				PACKAGE_NAME,
				'failed to parse form description in form submission',
			);
			// ignore description from backend if it's not a valid JSON format
			return undefined;
		}
	}, [description]);

	const analyticAttributes = useMemo(
		() => ({
			accessLevel: accessLevel?.toString(),
			isOutsideUser: !projectData || !projectData?.permissions.createIssues,
			formId,
		}),
		[accessLevel, projectData, formId],
	);

	const handleCreateIssueOnComplete = useCallback(() => {
		setIsVisible(false);
	}, [setIsVisible]);

	const handleResetClick = useCallback(() => {
		setIsVisible(true);
	}, [setIsVisible]);

	const renderExtraFields = useCallback(() => {
		setShouldRenderExtraFields(true);
		if (typeof document !== 'undefined') {
			interactions.forEach((evt) =>
				document.removeEventListener(evt, renderExtraFields, hasInitialInteractionCapture),
			);
		}
	}, []);
	const setRef = useIntersectionObserver(renderExtraFields);
	const setIntersectionRef = useCallback((element: Element | null) => setRef(element), [setRef]);

	const {
		create,
		data: createIssueData,
		error: createIssueError,
		errorMessage,
		loading: createIssueLoading,
	} = useCreateIssue(
		formSubmissionData?.formWithFieldData,
		handleCreateIssueOnComplete,
		projectData?.type,
	);

	const handleSubmitClick = useCallback(
		async (formData: FormInputData) => {
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'form submitted',
				'formSubmission',
				analyticAttributes,
			);
			await create(formData);
		},
		[create, createAnalyticsEvent, analyticAttributes],
	);

	const renderFields = useCallback(
		(fields: JiraBusinessFormWithFieldDataField[]) =>
			fields.map((f) => {
				// If the field no longer exists, do not render it.
				if (!fieldMetaData || !fieldMetaData[f.fieldId]) {
					return null;
				}

				const fieldConfig = getFieldTypeConfig(f.type, projectData?.type);

				if (!fieldConfig) {
					fireOperationalAnalytics(createAnalyticsEvent({}), 'field missing', {
						type: f.type,
					});
					return null;
				}

				const {
					autoCompleteUrl,
					allowedValues = [],
					defaultFieldValue,
					schema,
				} = fieldMetaData[f.fieldId];
				/**
				 * autoCompleteUrls contains origin/host.
				 * In storybook, we want these to point to our proxy.
				 */
				let completeUrl = autoCompleteUrl;
				if (Boolean(process.env.STORYBOOK_JIRA_URL) && typeof autoCompleteUrl === 'string') {
					const url = new URL(autoCompleteUrl);
					completeUrl = `${url.pathname}${url.search}`;
				}

				const isFieldRequired = f.isRequired || !!f.isRequiredByForm;
				const fieldDescription = f.description;

				const field = (
					<fieldConfig.Component
						key={f.fieldId}
						fieldName={typeof f.alias === 'string' && f.alias.length > 0 ? f.alias : f.name}
						fieldId={f.fieldId}
						isRequired={isFieldRequired}
						description={fieldDescription}
						autoCompleteUrl={completeUrl}
						allowedValues={allowedValues}
						mediaUploadContexts={{
							uploadContext: uploadPermissions,
							attachmentUploadContext: mediaUploadPermissions,
						}}
						projectId={String(projectId)}
						issueTypeId={issueType?.id}
						projectKey={projectData?.key}
						onInProgressChange={setUploading}
						defaultValue={defaultFieldValue}
						attachmentCustomUploadURL={getFormAttachmentUploadUrl(formId)}
						canUserViewProject={Boolean(projectData?.permissions?.viewIssues)}
						schemaConfiguration={schema?.configuration}
						renderer={fg('forms_multiline_text_wiki_renderer') ? f.renderer : undefined}
					/>
				);

				return f.parentCondition && fg('jira_forms_conditional_field_logic_gate_') ? (
					<Condition key={`${f.parentCondition.id}:${f.fieldId}`} condition={f.parentCondition}>
						{field}
					</Condition>
				) : (
					field
				);
			}),
		[
			fieldMetaData,
			uploadPermissions,
			mediaUploadPermissions,
			projectId,
			issueType?.id,
			formId,
			projectData?.permissions?.viewIssues,
			projectData?.key,
			projectData?.type,
			createAnalyticsEvent,
		],
	);

	const getErrorFlag = useCallback(
		() =>
			errorMessage !== null && typeof errorMessage === 'string'
				? submissionErrorFlag(errorMessage)
				: errorFlags.genericError(),
		[errorMessage],
	);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			interactions.forEach((evt) =>
				document.addEventListener(evt, renderExtraFields, hasInitialInteractionCapture),
			);
		}
		return () => {
			if (typeof document !== 'undefined') {
				interactions.forEach((evt) =>
					document.removeEventListener(evt, renderExtraFields, hasInitialInteractionCapture),
				);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof projectId === 'number') {
			fetchUploadPermissions(String(projectId), formId);
		}
	}, [fetchUploadPermissions, formId, projectId]);

	if (createIssueData?.success && !isVisible) {
		return (
			<div data-testid="business-form.ui.form-submission.fill-in-view.success-view">
				<SuccessView
					shouldHidePoweredBy={shouldHidePoweredBy}
					onResetClick={handleResetClick}
					analyticAttributes={analyticAttributes}
				/>
			</div>
		);
	}

	if (!formSubmissionData || !formWithFieldData || !fieldMetaData || isUploadPermissionNotReady) {
		return null;
	}

	// only render the first 15 fields for performance reasons
	// remaining fields will be rendered once the user interacts with the page
	const cloneInitialFields = [...formWithFieldData.fields];
	const initialFields = cloneInitialFields.splice(0, 15);
	const shouldRenderAdditionalFields = cloneInitialFields.length > 0 && shouldRenderExtraFields;

	return (
		<ContextualAnalyticsData
			objectType="form"
			objectId={formId}
			containerType="project"
			containerId={String(projectId)}
			attributes={{
				...generateFieldAnalytics(formWithFieldData.fields),
				...analyticAttributes,
			}}
		>
			<FireTrackAnalytics eventName="formSubmission viewed" />
			{projectData?.name ? (
				<ProjectNameWrapper>
					<Inline alignBlock="center" space="space.100">
						<Avatar appearance="square" size="small" src={projectData?.avatarUrl} />
						<Text as="span" color="color.text.subtle" weight="semibold">
							{projectData?.name}
						</Text>
					</Inline>
				</ProjectNameWrapper>
			) : (
				<Box xcss={projectNamePlaceholderStyles} />
			)}
			<Tooltip content={formWithFieldData.title} position="right">
				<TitleWrapperTruncated>{formWithFieldData.title}</TitleWrapperTruncated>
			</Tooltip>
			<DescriptionWrapper data-testid="business-form.ui.form-submission.fill-in-view.description">
				{parsedDescription ? (
					<Placeholder name="description-view" fallback={null}>
						<AsyncDescriptionReadView
							document={parsedDescription}
							mediaContext={mediaContext}
							featureFlags={{
								codeBidiWarnings: true,
								'code-bidi-warnings': true,
							}}
							// eslint-disable-next-line jira/deprecations/no-base-url
							baseUrl=""
						/>
					</Placeholder>
				) : null}
			</DescriptionWrapper>
			<Form
				isDisabled={createIssueLoading || (createIssueData?.success && !isVisible)}
				onSubmit={handleSubmitClick}
			>
				{({ formProps }) => (
					<form
						{...formProps}
						name="submit form"
						noValidate
						data-test-id="business-form.ui.form-submission.fill-in-view.form"
					>
						{renderFields(initialFields)}
						{shouldRenderAdditionalFields && renderFields(cloneInitialFields)}
						{!shouldRenderAdditionalFields && <span ref={setIntersectionRef} />}
						{(shouldRenderAdditionalFields || formWithFieldData.fields.length <= 15) && (
							<Footer
								isPublicForm={accessLevel === FormAccessLevelTypes.PUBLIC}
								createIssueLoading={createIssueLoading}
								uploading={uploading}
							/>
						)}
						{fg('jira_forms_conditional_field_logic_gate_') && <ScreenreaderAnnouncer />}
					</form>
				)}
			</Form>
			{createIssueError && (
				<div data-testid="business-form.ui.form-submission.fill-in-view.flag">
					<FlagRenderer flag={getErrorFlag()} />
				</div>
			)}
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectNameWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.200'),
	marginRight: 0,
	marginBottom: token('space.200'),
	marginLeft: 0,
});

const projectNamePlaceholderStyles = xcss({
	marginTop: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionWrapper = styled.div({
	marginTop: token('space.100'),
});

const submitButtonStyle = xcss({
	alignSelf: 'flex-end',
});
