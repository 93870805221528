import type {
	FieldPropertyTransformationResult,
	InvalidPropertyTransformationResult,
	ValidFieldTransformationResult,
} from './types';

export const mapValidResultsToFieldInternalShape = (results: FieldPropertyTransformationResult[]) =>
	results.reduce(
		(acc: ValidFieldTransformationResult, result) => {
			if (result.isValid === true && Object.prototype.hasOwnProperty.call(result, 'data')) {
				acc.data = {
					...acc.data,
					...result.data,
				};
			}
			return acc;
		},
		{ isValid: true, data: {} },
	);

export const filterInvalidResults = (validationResults: FieldPropertyTransformationResult[]) =>
	validationResults.reduce((acc: InvalidPropertyTransformationResult[], result) => {
		if (result.isValid === false) {
			acc.push(result);
		}
		return acc;
	}, []);
