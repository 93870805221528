import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type PropsWithChildren,
} from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { isFedRamp } from '@atlassian/atl-context';
import {
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { AsyncAiUpsellInEditorFreeModal as AIUpsellInEditorFreeModal } from '@atlassian/jira-growth-ai-upsell-in-editor-free-modal/src/ui/async.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FREE_EDITION, getEditionForProject } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useSiteHasOnlyJira } from '@atlassian/jira-billing/src/controllers/explicitly-licensed-products/use-site-has-only-jira.tsx';
import { getUpsellButtonWrapperClicked, setUpsellButtonWrapperClicked } from './utils.tsx';
import type {
	UseAIUpsellInEditorFreeResult,
	UseAIUpsellInEditorRequirementsResult,
	Product,
	SupportedProject,
} from './types.tsx';

const useGetSupportedProjectType = (product: Product): SupportedProject => {
	const siteHasOnlyJira = useSiteHasOnlyJira();

	switch (product) {
		case 'JSW':
			return SOFTWARE_PROJECT;
		case 'JSM':
			return SERVICE_DESK_PROJECT;
		case 'JWM':
			// If tenant is configured with only JWM and JSW, we can provide an upgrade link for JSW
			return siteHasOnlyJira ? SOFTWARE_PROJECT : undefined;
		case 'JPD':
		default:
			return undefined;
	}
};

const useGetSupportedEdition = (product: Product) => {
	const appEditions = useAppEditions();
	const projectType = useGetSupportedProjectType(product);

	if (!appEditions) {
		return undefined;
	}

	if (projectType) {
		return getEditionForProject(projectType, appEditions);
	}

	return undefined;
};

/**
 * This computes the requirements for being enrolled in an experiment without factoring in optInStatus
 * Exported for use in ../../use-ai-opt-in such that it returns "disabled-opt-in" for FREE sites
 * The experimentValue returned here should not be used outside of use-ai-opt-in
 */
export const useAIUpsellInEditorRequirements = (
	product: Product,
): UseAIUpsellInEditorRequirementsResult => {
	const isOrgAdmin = useIsSiteAdmin();
	const supportedEdition = useGetSupportedEdition(product);

	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [config, fireExperimentExposure] = UNSAFE_noExposureExp(
		'jira_free_ai_in_editor_v2_experiment',
	);
	const statsigExperimentValue = config.get<string>('cohort', 'not-enrolled');
	const siteMeetsRequirements =
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_free_ai_in_editor_v2_gate') &&
		supportedEdition === FREE_EDITION &&
		isOrgAdmin &&
		!isFedRamp();
	const experimentValue = siteMeetsRequirements ? statsigExperimentValue : 'not-enrolled';

	const fireExposure = useCallback(() => {
		fireExperimentExposure();
	}, [fireExperimentExposure]);

	return {
		siteMeetsRequirements,
		experimentValue,
		statsigExperimentValue,
		fireExperimentExposure: fireExposure,
	};
};

export const useAIUpsellInEditorFree = (
	optInStatus: string,
	product: Product,
): UseAIUpsellInEditorFreeResult => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [showUpsellModal, setShowUpsellModal] = useState(false);

	const { siteMeetsRequirements, statsigExperimentValue, fireExperimentExposure } =
		useAIUpsellInEditorRequirements(product);
	const projectType = useGetSupportedProjectType(product);

	const siteMeetsOptInRequirements =
		siteMeetsRequirements &&
		// optInStatus 'disabled' means they are on control, 'disabled-opt-in' on variation
		optInStatus !== 'enabled';

	const experimentValue = siteMeetsOptInRequirements ? statsigExperimentValue : 'not-enrolled';

	// If the site is not FREE, or not on JSW/JSM, or the user is not billable, they are not enrolled
	const isEnrolledInExperiment = experimentValue !== 'not-enrolled';
	const isExperimentEnabled = isEnrolledInExperiment && experimentValue === 'variation';

	const triggerUpsellFlow = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'triggerUpsellFlow',
				actionSubject: 'aiUpsellInEditorFree',
			}),
			'aiUpsellInEditorFreeTrigger',
			{},
		);

		setShowUpsellModal(true);
		setUpsellButtonWrapperClicked(true);
	}, [createAnalyticsEvent]);

	const UpsellModal = useMemo(
		() =>
			showUpsellModal && projectType ? (
				<AIUpsellInEditorFreeModal
					projectType={projectType}
					testId="platform-react-hooks-use-ai-upsell-in-editor-free.growth-ai-upsell-in-editor-modal"
					onModalClose={() => setShowUpsellModal(false)}
				/>
			) : null,
		[showUpsellModal, projectType],
	);

	return {
		isEnrolledInExperiment,
		isExperimentEnabled,
		fireExperimentExposure,
		triggerUpsellFlow,
		UpsellModal,
	};
};

export const AIUpsellButtonWrapper = ({ children }: PropsWithChildren<{}>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isClicked = getUpsellButtonWrapperClicked();
	const hasFired = useRef(false);

	useEffect(() => {
		if (!hasFired.current) {
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'button viewed',
				'aiUpsellInEditorFreeToolbarButton',
				{},
			);
			hasFired.current = true;
		}
	}, [createAnalyticsEvent]);

	return !isClicked ? (
		<AIUpsellButtonContainer data-testid="platform-react-hooks-use-ai-upsell-in-editor-free.ai-upsell-button-wrapper">
			<AIUpsellButtonInner>{children}</AIUpsellButtonInner>
		</AIUpsellButtonContainer>
	) : (
		<>{children}</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AIUpsellButtonContainer = styled.div({
	borderRadius: `calc(${token('border.radius', '3px')} + 1px)`,
	background: `conic-gradient(
    from 270deg,
    #0065FF 0%,
    #0469FF 20%,
    #BF63F3 50%,
    #FFA900 56%,
    #0065FF 100%
  )`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '1px',
	display: 'inline-flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AIUpsellButtonInner = styled.div({
	backgroundColor: token('elevation.surface'),
	borderRadius: token('border.radius', '3px'),
});
