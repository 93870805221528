import React, { type ReactNode, memo } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { JWM_CONTAINER } from '../../constants.tsx';
import { useSidebar } from '../../controllers/sidebar/index.tsx';
import { SidebarLayout, type SidebarTheme } from './sidebar-layout/index.tsx';

type BusinessAppWrapperProps = {
	className?: string;
	header?: ReactNode;
	sidebarTheme?: SidebarTheme;
	shouldAddPadding?: boolean;
	withSidebar?: boolean;
	withDynamicHeight?: boolean;
	withOverflow?: boolean;
	children: ReactNode;
};

// This wrapper should be agnostic, please don't add any project or overview logic here.
const BusinessAppWrapper = ({
	children,
	className,
	header,
	sidebarTheme,
	shouldAddPadding = true,
	withSidebar = false,
	withDynamicHeight = false,
	withOverflow = false,
}: BusinessAppWrapperProps) => {
	const [{ content: sidebarContent }] = useSidebar();
	const isSidebarOpen = withSidebar && sidebarContent != null;

	let content = (
		<>
			{header}
			{children}
		</>
	);

	if (withSidebar) {
		content = (
			<SidebarLayout theme={sidebarTheme}>
				<Box xcss={[contentWrapperStyles, isSidebarOpen && sidebarOpenContentWrapperStyles]}>
					{content}
				</Box>
			</SidebarLayout>
		);
	}

	return (
		<ThemedContainer
			shouldAddPadding={shouldAddPadding}
			isSidebarOpen={isSidebarOpen}
			withSidebar={withSidebar}
			withDynamicHeight={withDynamicHeight}
			withOverflow={withOverflow}
			id={JWM_CONTAINER}
			// Used for styling
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={className}
		>
			{content}
		</ThemedContainer>
	);
};

const contentWrapperStyles = xcss({
	// @ts-expect-error - we need to use Tokens to correctly set the color value using CSS variables
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	color: Tokens.COLOR_TEXT,
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexShrink: 1,
	maxWidth: '100%',
	minWidth: '0',
	width: 'auto',
});

const sidebarOpenContentWrapperStyles = xcss({
	paddingRight: 'space.300',
	paddingBottom: 'space.300',
});

export default memo<BusinessAppWrapperProps>(BusinessAppWrapper);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ThemedContainer = styled.div<{
	shouldAddPadding?: boolean;
	isSidebarOpen?: boolean;
	withSidebar?: boolean;
	withDynamicHeight?: boolean;
	withOverflow?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ withSidebar }) => (withSidebar ? undefined : Tokens.COLOR_TEXT),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ withDynamicHeight }) => (withDynamicHeight ? 'auto' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ withDynamicHeight }) => (withDynamicHeight ? '100%' : 'auto'),
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'flex-start',
	paddingTop: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ shouldAddPadding, isSidebarOpen }) =>
		!shouldAddPadding || isSidebarOpen ? 0 : token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ shouldAddPadding, isSidebarOpen }) =>
		!shouldAddPadding || isSidebarOpen ? 0 : token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ shouldAddPadding }) => (shouldAddPadding ? token('space.300') : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ withOverflow }) => (withOverflow ? 'auto' : 'visible'),
});
