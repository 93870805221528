import React, { useEffect } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { Box, Bleed, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { B400, P400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import JiraPremiumAIBanner from '@atlassian/jira-illustrations/src/ui/adg4/jira/other/components/ai-chat-loading-promoted/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';
import { StartPremiumTrialButton } from './start-premium-trial-button.tsx';

type Props = {
	onClose: () => void;
};

export const AIOptInPremiumUpsell = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		const analyticsEvent = createAnalyticsEvent({
			actionSubject: 'screen',
			action: 'viewed',
		});

		fireUIAnalytics(analyticsEvent, 'IssueAiUpsellDialog');
	}, [createAnalyticsEvent]);

	return (
		<Box xcss={containerBorderStyles}>
			<Stack alignBlock="stretch" xcss={containerStyles}>
				<Inline alignInline="end" grow="fill" xcss={headerStyles}>
					<IconButton
						appearance="subtle"
						onClick={onClose}
						label={formatMessage(messages.closeModal)}
						icon={EditorCloseIcon}
					/>
				</Inline>
				<Bleed all="space.025">
					<JiraPremiumAIBanner alt="" />
				</Bleed>
				<Stack alignInline="stretch" space="space.200" xcss={textContainerStyles}>
					<Heading size="medium" as="h2">
						{formatMessage(messages.title)}
					</Heading>
					<Text as="p">{formatMessage(messages.body)}</Text>
					<Inline alignInline="end" grow="fill">
						<StartPremiumTrialButton onCloseParent={onClose} />
					</Inline>
				</Stack>
			</Stack>
		</Box>
	);
};

const containerBorderStyles = xcss({
	overflow: 'hidden',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: 'border.radius',
	width: '438px',
	position: 'relative',
	padding: 'space.025',
	background: `linear-gradient(
      112.4deg,
      ${token('color.background.accent.blue.subtle', B400)} 0%,
      ${token('color.background.accent.purple.subtle', P400)} 100%
    )`,
});

const containerStyles = xcss({
	borderRadius: 'border.radius',
	backgroundColor: 'elevation.surface.overlay',
	overflow: 'hidden',
});

const headerStyles = xcss({
	position: 'absolute',
	padding: 'space.075',
	right: 'space.025',
});

const textContainerStyles = xcss({
	padding: 'space.300',
});
