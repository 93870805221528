import type { ApolloError } from 'apollo-client';
import { fg } from '@atlassian/jira-feature-gating';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import { getFieldOptions } from '../../common/utils/field-options/index.tsx';
import type { FieldsMeta } from '../field-meta/types.tsx';
import { isFieldTypeSupportedForPublicAccessType } from '../../common/utils/field-config/index.tsx';
import type { JiraBusinessFormWithFieldDataByFormUuid } from './__generated_apollo__/JiraBusinessFormWithFieldDataByFormUuid.ts';
import { FORM_NOT_FOUND_ERROR } from './constants.tsx';
import type {
	JiraBusinessFormWithFieldData,
	JiraBusinessFormWithFieldDataField,
} from './types.tsx';

export const transformPublicFormDataWithFieldData = ({
	data,
}: {
	data?: JiraBusinessFormWithFieldDataByFormUuid | null;
}): JiraBusinessFormWithFieldData | undefined => {
	if (!data || !data.jiraFormForPublicSubmission) {
		return undefined;
	}

	const { jiraFormForPublicSubmission } = data;
	const { formId, projectId, title, description, bannerColor, enabled, fields } =
		jiraFormForPublicSubmission;

	return {
		formId,
		projectId,
		title,
		description: description ?? undefined,
		bannerColor: bannerColor ?? undefined,
		enabled,
		fields:
			fields
				?.filter(Boolean)
				.filter((f) => 'fieldConfig' in f.field)
				.map((f) => {
					const fieldIsRequiredByIssueConfig =
						'fieldConfig' in f.field && Boolean(f.field.fieldConfig?.isRequired);
					const fieldIsRequiredByFormConfig = !!f.isRequired;

					return {
						fieldId: f.fieldId,
						alias: f.alias,
						name: f.field.name,
						isRequired: fieldIsRequiredByIssueConfig,
						isRequiredByForm: fieldIsRequiredByFormConfig,
						description: f.description ?? undefined,
						type: f.field.type,
						...(fg('jira_forms_conditional_field_logic_gate_') && {
							parentCondition: f.parentCondition,
						}),
						...('renderer' in f.field &&
							fg('forms_multiline_text_wiki_renderer') && {
								renderer: f.field.renderer ?? undefined,
							}),
						fieldOptions: getFieldOptions(f.field) ?? undefined,
					};
				})
				.filter((field) => isFieldTypeSupportedForPublicAccessType(field.type)) ?? [],
		accessLevel: FormAccessLevelTypes.PUBLIC,
	};
};

export const isFormNotFoundError = (error: ApolloError) =>
	error.graphQLErrors?.[0]?.extensions?.errorType === FORM_NOT_FOUND_ERROR;

/**
 * Used by public form submission to get a field meta shape that's the same as
 * rest data, but from the graphql response
 */
export const transformFieldMetaFromStore = (
	fields: JiraBusinessFormWithFieldDataField[] | undefined,
): FieldsMeta | undefined => {
	if (!fields) {
		return undefined;
	}

	return fields.reduce((acc: FieldsMeta, field) => {
		acc[field.fieldId] = {
			name: field.name,
			required: field.isRequired,
			schema: {
				custom: field.type,
			},
			allowedValues:
				field.fieldOptions?.map((option) => ({
					id: option.value,
					value: option.label,
					self: '',
				})) ?? undefined,
		};
		return acc;
	}, {});
};
