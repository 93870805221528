import type { StoreActionApi } from 'react-sweet-state';

import type { IssueAdjustmentsState } from '../../types';

import type { ErrorState } from './types';

export const setError =
	(errorProperties: ErrorState) =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			error: {
				errorCode: errorProperties.errorCode,
				fieldName: errorProperties.fieldName,
			},
		});
	};

export const resetError =
	() =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			error: null,
		});
	};
