import type { ValidationError } from './types';

type ErrorAttributes = { errors: ValidationError[] };

export class UiModificationsValidationError extends Error {
	attributes: ErrorAttributes;

	validationErrorType: string;

	constructor(
		message: string,
		attributes: ErrorAttributes,
		validationErrorType: string,
		cause?: Error,
	) {
		super(message, cause);
		this.attributes = attributes;
		this.validationErrorType = validationErrorType;
	}
}
