import { blockquote as blockquoteFactory } from '../../next-schema/generated/nodeTypes';
import { blockquoteLegacy as blockquoteLegacyFactory, blockquoteWithNonBodiedMacrosStage0 as blockquoteWithNonBodiedMacrosStage0Factory, blockquoteWithoutNestedCodeblockOrMedia as blockquoteWithoutNestedCodeblockOrMediaFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name blockquote_node
 */

const nodeSpecOptions = {
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
};
export const blockquote = blockquoteLegacyFactory(nodeSpecOptions);
export const blockquoteWithNestedCodeblockOrMedia = blockquoteFactory(nodeSpecOptions);

/**
 * @name blockquote_with_list_node
 */
export const blockquoteWithList = blockquoteWithoutNestedCodeblockOrMediaFactory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
});
export const blockquoteWithNonBodiedMacrosStage0 = blockquoteWithNonBodiedMacrosStage0Factory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM() {
    return ['blockquote', 0];
  }
});