import { useState } from 'react';

import { di } from 'react-magnetic-di';

import type { ViewConfiguration } from '../../../common/types';
import type { ViewType } from '../../../common/types/context';

const VIEW_CONFIGURATIONS = new Map<ViewType, ViewConfiguration>();

/**
 * Loads view configuration for a given view type.
 *
 * @internal Use useLoadViewConfiguration hook.
 */
export const loadViewConfiguration = (viewType: ViewType, configuration: ViewConfiguration) => {
	if (!VIEW_CONFIGURATIONS.has(viewType)) {
		VIEW_CONFIGURATIONS.set(viewType, configuration);
	}
};

/**
 * Returns view configuration for a given view type.
 */
export const getViewConfiguration = (viewType: ViewType) => VIEW_CONFIGURATIONS.get(viewType);

/**
 * Clears view configurations.
 *
 * @internal For tests only.
 */
export const clearViewConfigurations = () => {
	VIEW_CONFIGURATIONS.clear();
};

/**
 * Loads UI modifications view configuration once.
 */
export const useLoadViewConfiguration = (
	viewType: ViewType,
	configurationClass: new () => ViewConfiguration,
) => {
	di(loadViewConfiguration);
	/**
	 * Uses useState to load view configuration on component mount, before rendering.
	 */
	useState(() => {
		loadViewConfiguration(viewType, new configurationClass());
	});
};
