import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';

export const usePublicFormsGateEnabledAnalyticsAttrs = () =>
	useMemo(
		() => ({
			isPublicFormsGateEnabled: fg('jira-forms-public-access'),
		}),
		[],
	);
