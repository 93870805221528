import { HASH_RANDOMISER, MOD_VALUE } from './constants.tsx';

export const getHashValueForText = (text: string) => {
	let hashValue = 0;

	for (let i = 0; i < text.length; i++) {
		hashValue = (hashValue * HASH_RANDOMISER + text.charCodeAt(i)) % MOD_VALUE;
	}

	return hashValue;
};
