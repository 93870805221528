import type { StoreActionApi } from 'react-sweet-state';

import type { AppId, AppsErrorsMap, IssueAdjustmentsState } from '../../types';
import type { AppError } from '../../utils/errors/types';

/**
 * Sets errors for a single app.
 */
export const setAppErrors =
	({ errors, appId }: { errors: AppError[]; appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const previousState: AppsErrorsMap = getState().appsErrors ?? {};
		setState({
			appsErrors: {
				...previousState,
				[appId]: errors,
			},
		});
	};

/**
 * Adds errors to the existing errors for mulitple apps.
 * It merges the errors for each appId with the existing ones.
 */
export const addAppsErrors =
	({ errors }: { errors: Record<AppId, AppError[]> }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const previousState: AppsErrorsMap = getState().appsErrors ?? {};

		const appsErrors = Object.entries(errors).reduce(
			(acc, [appId, appErrors]) => {
				acc[appId] = [...(acc[appId] ?? []), ...appErrors];

				return acc;
			},
			{ ...previousState },
		);

		setState({ appsErrors });
	};

export const resetAppErrors =
	({ appId }: { appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const previousState: AppsErrorsMap = getState().appsErrors ?? {};

		setState({
			appsErrors: {
				...previousState,
				[appId]: null,
			},
		});
	};
