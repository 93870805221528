import type { StoreActionApi } from 'react-sweet-state';

import type { IssueAdjustmentsState } from '../../types';

/**
 * Initialize screen tabs store data with an empty array
 */
export const initializeNoScreenTab =
	() =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			screenTabs: [],
		});
	};
