import gqlTagGira from 'graphql-tag';

/**
 * This is used for Form builder
 */
export const FORM_BUILDER_CONFIGURATION_FRAGMENT = gqlTagGira`
 fragment FormBuilderConfigurationFragment on JiraBusinessFormConfiguration {
    id
    uuid @include(if: $isPublicFormsEnabled)
    formId
    projectId
    title
    description
    bannerColor
    enabled
    isSubmittableByAllLoggedInUsers
    accessLevel
    fields {
      id
      fieldId
      alias
      description
      isRequired
      parentCondition @include(if: $isConditionalFieldsEnabled) {
        id
      }
      conditions @include(if: $isConditionalFieldsEnabled) {
        __typename
        id
        ... on JiraFormSelectCondition {
          fieldOptionIds
          operator
        }
      }
      field @include(if: $isConditionalFieldsEnabled) {
        __typename
        id
		... on JiraSingleSelectField {
			fieldOptions {
				edges {
					node {
						id
						optionId
						value
					}
				}
			}
		}
		... on JiraMultipleSelectField {
			fieldOptions {
				edges {
					node {
						id
						optionId
						value
					}
				}
			}
		}
		... on JiraRadioSelectField {
			fieldOptions {
				edges {
					node {
						id
						optionId
						value
					}
				}
			}
		}
		... on JiraCheckboxesField {
			fieldOptions {
				edges {
					node {
						id
						optionId
						value
					}
				}
			}
		}
		... on JiraPriorityField {
			priorities {
				edges {
					node {
						id
						priorityId
						name
						iconUrl
					}
				}
			}
		}
      }
    }
    issueType {
      issueTypeId
      name
      type
      avatarId
      avatar {
        medium
      }
      hierarchy {
        level
      }
    }
 }
`;

/**
 * This is used for Form directory
 */
export const FORM_CONFIGURATION_FOR_DIRECTORY = gqlTagGira`
  fragment FormConfigurationFragmentForDirectory on JiraBusinessFormConfiguration {
    id
    formId
    title
    description
    bannerColor
    enabled
    updated
    accessLevel
    issueType {
      issueTypeId
      name
      avatarId
      avatar {
        medium
      }
    }
    updateAuthor {
      name
      accountId
    }
    errors
  }
`;

export const MUTATION_ERROR_FRAGMENT = gqlTagGira`
  fragment MutationErrorFragment on MutationError {
    message
    extensions {
      statusCode
      errorType
    }
  }
`;

export const FIELD_PARENT_CONDITION_FRAGMENT = gqlTagGira`
	fragment FieldParentConditionFragment on JiraFormFieldCondition {
		__typename
		id
		... on JiraFormSelectCondition {
			fieldOptionIds
			operator
		}
		parentField {
			id
			fieldId
			field {
				__typename
				id
			}
		}
	}
`;
