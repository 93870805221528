import type { ViewType } from '../../../../common/types/context';
import type { FieldInternalShape, FieldType } from '../../../../common/types/field';
import type { FieldChangesPublic } from '../../../../common/types/fields/field-changes';
import { getSupportedFieldConfiguration } from '../../../view-configuration';

export const mapFieldPropertyNamesToInternal = (
	properties: FieldChangesPublic,
	viewType: ViewType,
	fieldType: FieldType,
): FieldInternalShape => {
	const fieldConfiguration = getSupportedFieldConfiguration(viewType, fieldType);
	const propertyMapping = fieldConfiguration?.propertyMapping;

	if (!propertyMapping) {
		return properties;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (Object.keys(properties) as [keyof FieldChangesPublic]).reduce(
		(acc: FieldInternalShape, propertyName): FieldInternalShape => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const propertyMappingValue = propertyMapping[propertyName] as keyof FieldInternalShape;
			acc[propertyMappingValue ?? propertyName] = properties[propertyName];
			return acc;
		},
		{},
	);
};
