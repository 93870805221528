import { useState, useEffect } from 'react';
import memoizeOne from 'memoize-one';
import { REQUEST_END, REQUEST_START } from '@atlassian/jira-business-performance/src/constants.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormAccessLevelTypes } from '../../common/types.tsx';
import { useFieldMetaFromStore } from '../../controllers/form-submission/store/index.tsx';
import { transformFieldMetaFromStore } from '../get-form-public/utils.tsx';
import type { FieldMetaState, MetaApi } from './types.tsx';
import { filterFieldsMeta, transformFieldsMeta } from './utils.tsx';

const fetchMeta = async (formId: string) => {
	// https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-issues/#api-rest-api-2-issue-createmeta-get
	const response: MetaApi = await fetchJson(
		`/rest/api/2/issue/createmeta/businessform?formId=${formId}&expand=projects.issuetypes.fields`,
	);
	return response;
};

const memoizedFetchMeta: typeof fetchMeta = memoizeOne(fetchMeta);

export const useFieldMeta = (
	projectId?: string | number,
	issueTypeId?: string,
	formId?: string,
	accessLevel?: FormAccessLevelTypes,
): FieldMetaState => {
	const [state, setState] = useState<FieldMetaState>({
		loading: true,
	});
	const fieldMetaFromStore = useFieldMetaFromStore();

	useEffect(() => {
		// TODO Inline these check variables on jira-forms-public-access cleanup
		const conditionalAccessLevelCheck = accessLevel == null && fg('jira-forms-public-access');
		const conditionalIssueTypeCheck =
			issueTypeId == null &&
			accessLevel !== FormAccessLevelTypes.PUBLIC &&
			fg('jira-forms-public-access');

		// Early exit if invalid data
		if (
			projectId == null ||
			formId == null ||
			conditionalAccessLevelCheck ||
			conditionalIssueTypeCheck
		) {
			setState({ loading: false });
			return;
		}

		// Public forms grabs meta data from the store, don't fetch from createmeta/businessform
		if (accessLevel === FormAccessLevelTypes.PUBLIC && fg('jira-forms-public-access')) {
			try {
				setState({
					data: transformFieldMetaFromStore(fieldMetaFromStore),
					loading: false,
					error: undefined,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				setState({
					loading: false,
					error: e.message,
				});
			}
			return;
		}

		// Limited/open forms continue to fetch
		(async () => {
			setState({
				loading: true,
			});

			markViewMetric('form-submit', REQUEST_START);

			try {
				// https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-issues/#api-rest-api-2-issue-createmeta-get
				const response = await memoizedFetchMeta(formId);

				let data;

				// if there's a permission error, the response will exist but projects will be an empty array
				if (response.projects[0]?.issuetypes[0]?.fields) {
					data = transformFieldsMeta(filterFieldsMeta(response.projects[0].issuetypes[0].fields));
				}

				setState({
					// @ts-expect-error - TS2322 - Type '{ [x: string]: FieldMeta | { required: boolean; autoCompleteUrl?: string | undefined; schema?: { custom?: string | undefined; system?: string | undefined; } | undefined; defaultFieldValue?: string | undefined; allowedValues: CascadingAllowableValue[]; }; } | undefined' is not assignable to type 'FieldsMeta | undefined'.
					data,
					loading: false,
					error: undefined,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				setState({
					loading: false,
					error: e.message,
				});
			} finally {
				markViewMetric('form-submit', REQUEST_END);
			}
		})();
	}, [formId, issueTypeId, projectId, accessLevel, fieldMetaFromStore]);

	return state;
};
