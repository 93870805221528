import React from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useIntl } from '@atlassian/jira-intl';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useNativeLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { preventFormSubmitOnEnterKeyPress } from '../../common/utils/date/index.tsx';
import { validateString } from '../../common/utils/validators/index.tsx';
import messages from './messages.tsx';
import type { Props, DateFieldConfig, FieldValue } from './types.tsx';

const DateField = (props: Props) => {
	const {
		autoFocus = false,
		width,
		fieldProps: { value = '', onChange, id },
		error,
		isReadOnly = false,
	} = props;
	const locale = useNativeLocale();
	const { formatMessage } = useIntl();

	const weekStartDay = getLocale(locale)?.options?.weekStartsOn;

	const safeValue = value === null ? undefined : value;

	return (
		<FieldContainer width={width}>
			<DatePicker
				value={safeValue}
				isDisabled={isReadOnly}
				onChange={onChange}
				autoFocus={autoFocus}
				locale={locale}
				isInvalid={Boolean(error)}
				selectProps={{
					/**
						Temporary fix to prevent form submit on enter press on an open calendar.
						Ideally this should either be handled by atlaskit or keypress enter event should only be supressed when calendar is open.
						There is no way to know if the calendar is open in the current implementation

						Side Effect: Form will not submit on enter keyPress even when calendar is not open.
					*/
					onKeyDown: preventFormSubmitOnEnterKeyPress,
				}}
				weekStartDay={weekStartDay}
				label={formatMessage(messages.ariaLabel)}
				aria-describedby={`${id}-helper`}
				id={id}
				placeholder={formatMessage(messages.placeholder)}
				shouldShowCalendarButton
				inputLabel={formatMessage(messages.ariaLabel)}
			/>
		</FieldContainer>
	);
};

// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<DateFieldConfig, FieldValue>, any>'.
export default withFormField({ validator: validateString })<DateFieldConfig, FieldValue>(DateField);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateDateField, transformDate } from './utils';
