import { fireErrorAnalytics } from '@atlassian/ui-modifications-analytics';

import type { ViewType } from '../../../../common/types/context';
import type {
	FieldInternalShape,
	FieldPublicShape,
	FieldType,
} from '../../../../common/types/field';
import type { FieldInternalMetadata } from '../../../../common/types/field-internal-metadata';
import type { LookupData } from '../../../../common/types/lookup';
import { getSupportedFieldConfiguration } from '../../../view-configuration';

export const transformFieldToIframe = (
	fieldChanges: FieldInternalShape,
	viewType: ViewType,
	fieldType: FieldType,
	lookupData: LookupData,
	fieldFromIframe: FieldPublicShape,
	fieldMetadata: FieldInternalMetadata,
): FieldPublicShape => {
	const fieldConfiguration = getSupportedFieldConfiguration(viewType, fieldType);
	const fieldInternalToPublicTransformers = fieldConfiguration?.internalToPublicTransformers;

	const publicShapeField: FieldPublicShape = { ...fieldChanges };

	if (publicShapeField.value !== undefined && fieldInternalToPublicTransformers?.value) {
		try {
			publicShapeField.value = fieldInternalToPublicTransformers?.value(
				fieldChanges.value,
				fieldMetadata.fieldRenderer,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireErrorAnalytics({
				error: err,
				id: 'internalToPublicValueTransformer',
				viewType,
				attributes: {
					fieldType,
				},
			});
		}
	}

	if (
		fieldFromIframe.optionsVisibility !== undefined &&
		fieldInternalToPublicTransformers?.optionsVisibility
	) {
		try {
			const lookupSource = fieldConfiguration?.lookupSource;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore - lookupData is not types on CI somehow
			const lookupValues = lookupSource ? lookupData[lookupSource] : undefined;

			publicShapeField.optionsVisibility = fieldInternalToPublicTransformers?.optionsVisibility(
				fieldFromIframe.optionsVisibility,
				lookupValues,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			fireErrorAnalytics({
				error: err,
				id: 'internalToPublicOptionsVisibilityTransformer',
				viewType,
				attributes: {
					fieldType,
				},
			});
		}
	}

	return publicShapeField;
};
